/* eslint tailwindcss/no-custom-classname: off */
import { FeatureXs } from './FeatureXs'
import { chunk } from '@/utils/format'
import { Feature } from '@/types/feature'

interface Props {
  features: Feature[]
  column: number
}

export const FeaturesL: React.FC<Props> = ({ features, column }) => {
  const iterateFeatures = chunk(features, column)
  return (
    <>
      <div className="mb-10">
        {iterateFeatures.map((iterateFeature) => (
          <div key={`feature-table-${iterateFeature[0]['id']}`}>
            <div className="table sm:p-0 px-[10px] mb-0">
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4 w-full">
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white">
                    <FeatureXs feature={iterateFeature[0]} />
                  </div>
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white">
                    {iterateFeature[1] && (
                      <FeatureXs feature={iterateFeature[1]} />
                    )}
                  </div>
                </div>
              </div>
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4 w-full">
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white">
                    {iterateFeature[2] && (
                      <FeatureXs feature={iterateFeature[2]} />
                    )}
                  </div>
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white">
                    {iterateFeature[3] && (
                      <FeatureXs feature={iterateFeature[3]} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
