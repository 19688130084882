import { PlaylistXs } from './PlaylistXs'
import { chunk } from '@/utils/format'
import { PlaylistShort } from '@/types/playlist'

interface Props {
  playlistShorts: PlaylistShort[]
  column: number
}

export const PlaylistL: React.FC<Props> = ({ playlistShorts, column }) => {
  const iteratePlaylists = chunk(playlistShorts, column)
  return (
    <>
      <div className="mb-10">
        {iteratePlaylists.map((iteratePlaylist) => (
          <div key={`playlist-table-${iteratePlaylist[0]['id']}`}>
            <div className="table sm:p-0 px-[10px] mb-0">
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4 w-full">
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    <PlaylistXs playlistShort={iteratePlaylist[0]} />
                  </div>
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    {iteratePlaylist[1] && (
                      <PlaylistXs playlistShort={iteratePlaylist[1]} />
                    )}
                  </div>
                </div>
              </div>
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4 w-full">
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    {iteratePlaylist[2] && (
                      <PlaylistXs playlistShort={iteratePlaylist[2]} />
                    )}
                  </div>
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    {iteratePlaylist[3] && (
                      <PlaylistXs playlistShort={iteratePlaylist[3]} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
