interface Props {
  title: string
  id: string
}
export const FeatureImage: React.FC<Props> = ({ title, id }) => {
  return (
    <div className="w-full">
      <img
        className="w-full bg-white"
        src={`${process.env.IMG_SERVER}/feature/${id}_eyecatch_mini.jpg`}
        alt={title}
        loading="lazy"
      />
    </div>
  )
}
