export const BusinessLabel: React.FC = () => {
  return (
    <>
      <div
        className="flex items-start p-[5px] w-[70px] h-5 text-center"
        style={{
          backgroundImage: 'linear-gradient(87deg, #145DAB 0%, #22B8B0 100%)',
        }}
      >
        <span className="w-[100px] h-[19px] text-[14px] not-italic font-medium leading-[10px] text-white whitespace-nowrap">
          business
        </span>
      </div>
    </>
  )
}
