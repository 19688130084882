import { BusinessLabel } from './BusinessLabel'

interface Props {
  className?: string
  title: string
  showShadowTitle?: boolean
}
export const H1Business: React.FC<Props> = ({
  className,
  title,
  showShadowTitle = true,
}) => {
  return (
    <h1 className={`text-[24px] text-fl-black ${className || ''}`}>
      <div
        className={`flex justify-center m-auto w-fit gap-[10px] ${
          showShadowTitle && 'shadow-title'
        }`}
      >
        <span
          className={`text-center leading-normal ${
            showShadowTitle && 'shadow-title'
          }`}
        >
          {title}
        </span>
        <div className="mt-[6px]">
          <BusinessLabel />
        </div>
      </div>
    </h1>
  )
}
