import { BizGroupCluster } from '@/types/user'
import { getClusterName } from '@/utils/user'

interface Props {
  bizGroupClusters: BizGroupCluster[]
  clusterId: number
  useBizGroupCluster: boolean
  className?: string
}

export const BizGroupClusterNameLabel: React.FC<Props> = ({
  bizGroupClusters,
  clusterId,
  useBizGroupCluster,
  className = '',
}) => {
  if (!useBizGroupCluster || clusterId === 0) return null
  return (
    <div
      className={`py-1 px-2 font-flier text-xs font-bold text-center text-fl-sky truncate whitespace-nowrap bg-fl-unread ${className}`}
    >
      to {getClusterName(bizGroupClusters, clusterId)}
    </div>
  )
}
