import { ArrowRightSmall } from '@/components/icon/ArrowRightSmall'
import { ReadingProgramIcon } from '@/components/icon'
import { useRouter } from 'next/router'

export const ReadingProgramLink: React.FC = () => {
  const router = useRouter()
  const onClick = () => {
    router.push('/reading-programs/list')
  }
  return (
    <button className="w-full" onClick={onClick}>
      <div className="flex justify-between items-center p-3 mt-2 font-bold hover:bg-fl-gray2 rounded-[4px] border-[1px] border-fl-gray1">
        <div className="flex gap-[5px] items-center text-[14px]">
          <ReadingProgramIcon className="w-6 h-6 text-[#1a6dcc]" />
          読書プログラム
        </div>
        <span className="flex justify-center items-center text-xs not-italic font-bold text-[#1a6dcc]">
          一覧を見る
          <ArrowRightSmall color="#1a6dcc" size={13} className="ml-1" />
        </span>
      </div>
    </button>
  )
}
