/* eslint-disable tailwindcss/no-custom-classname */
import { CircularButton } from '@/components/button/CircularButton'
import { isErrorResponse } from '@/types/error'
import { GetReleaseListResponse } from '@/types/release'
import { useRouter } from 'next/router'
import { ReleaseItem } from './ReleaseItem'
interface Props {
  latestReleases: GetReleaseListResponse
}

export const Releases: React.FC<Props> = ({ latestReleases }) => {
  const router = useRouter()
  return (
    <div className="block relative mb-[50px] w-full">
      <div className="block sm:flex relative py-[25px] px-[10px] sm:px-0 mx-auto w-full sm:w-[1024px] bg-white min-w-5xl">
        <div className="table mb-0 w-full max-w-max">
          <div className="block sm:table-cell w-full sm:w-48 align-top">
            <div className="mt-0 sm:mt-20">
              <div className="relative mx-auto mt-[30px] mb-5 min-h-[50px] font-medium text-center">
                <div className="text-2xl leading-normal text-fl-black">
                  <span>お知らせ</span>
                </div>
              </div>
            </div>
            <div className="mt-[30px] mb-5 text-center">
              <CircularButton
                text="すべてを見る"
                onClick={() => router.push('/release/list')}
              />
            </div>
          </div>
          <div className="w-5"></div>
          <div className="table-cell w-full align-top">
            {!isErrorResponse(latestReleases) && latestReleases?.result ? (
              <>
                {latestReleases.releases.slice(0, 5).map((release) => (
                  <ReleaseItem
                    release={release}
                    key={`release-item-${release['id']}`}
                  />
                ))}
              </>
            ) : (
              <div>データの取得に失敗しました</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
