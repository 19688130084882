/* eslint tailwindcss/no-custom-classname: off */
import useSWR from 'swr'
import { isMobile } from 'react-device-detect'
import { Banner, Category } from './'
import {
  GetLatestSummaryResponse,
  GetMasterpieceResponse,
  GetSummaryRankingResponse,
  GetTopRecommendSummaryResponse,
} from '@/types/summary'
import { GetChannelMoviesResponse } from '@/types/channel'
import { GetFeatureListResponse } from '@/types/feature'
import { GetCategoryListResponse } from '@/types/category'
import { BizWeeklyRankingResponse } from '@/types/business_group'
import { useEffect, useState } from 'react'
import { Summary } from './Summary/Summary'
import { Channel } from './Channel/Channel'
import { ChannelBanner } from './Channel/Banner'
import { Feature } from './Feature/Feature'
import { Playlist } from './Playlist'
import { ReadingProgram } from './ReadingProgram'
import { TopBannerResponse } from '@/types/top_banner'
import { SpecialBanner } from './Banner/SpecialBanner'
import { PlaylistsResponse } from '@/types/playlist'
import { ReadingProgramsResponse } from '@/types/reading_program'

interface Props {
  latestSummary: GetLatestSummaryResponse
  weeklyRanking: GetSummaryRankingResponse
  monthlyRanking: GetSummaryRankingResponse
  bizWeeklyRanking: BizWeeklyRankingResponse
  categoryList: GetCategoryListResponse
  isBizSrpClose: boolean
  bizGroupName: string
  isBizUser: boolean
  topBanner?: TopBannerResponse
  playlists: PlaylistsResponse
  useReadingProgram?: boolean
  readingPrograms?: ReadingProgramsResponse
}

export const Gold: React.FC<Props> = ({
  latestSummary,
  weeklyRanking,
  monthlyRanking,
  bizWeeklyRanking,
  categoryList,
  isBizSrpClose,
  bizGroupName,
  isBizUser,
  topBanner,
  playlists,
  useReadingProgram,
  readingPrograms,
}) => {
  const [rankingIndex, setRankingIndex] = useState(4)
  const [masterpieceIndex, setMasterpieceIndex] = useState(4)
  const [recommendIndex, setRecommendIndex] = useState(8)
  const [isSp, setIsSp] = useState(true)

  useEffect(() => {
    /** 初期レンダリングが終わったあとに要素の数を変更する */
    setRankingIndex(isMobile ? 6 : 4)
    setMasterpieceIndex(isMobile ? 6 : 4)
    setRecommendIndex(isMobile ? 12 : 8)
  }, [])

  useEffect(() => {
    if (isMobile) {
      setIsSp(true)
    } else {
      setIsSp(false)
    }
  }, [])

  /** いま読んでおきたい話題書 */
  const { data: masterpiece, error: masterpieceError } =
    useSWR<GetMasterpieceResponse>('/api/summary/masterpiece?page=1&limit=6')
  /** あなたにオススメ */
  const { data: recommendForYou, error: recommendForYouError } =
    useSWR<GetTopRecommendSummaryResponse>(
      '/api/summary/top_recommend?page=1&limit=12'
    )
  /** flierチャンネル */
  const { data: latestChannel, error: latestChannelError } =
    useSWR<GetChannelMoviesResponse>('/api/channel/movies')
  /** イチオシの本 */
  const { data: latestPickUp, error: latestPickUpError } =
    useSWR<GetFeatureListResponse>('/api/feature/pickup')
  /** インタビュー */
  const { data: latestInterview, error: latestInterviewError } =
    useSWR<GetFeatureListResponse>('/api/feature/interview')
  /** スペシャルコラム */
  const { data: latestColumn, error: latestColumnError } =
    useSWR<GetFeatureListResponse>('/api/feature/column')
  /** スキルアップ・キャリア */
  const { data: categoryRanking1, error: categoryRanking1Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=1&page=1&limit=4'
    )
  /** 経営戦略 */
  const { data: categoryRanking5, error: categoryRanking5Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=5&page=1&limit=4'
    )
  /** 人事 */
  const { data: categoryRanking7, error: categoryRanking7Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=7&page=1&limit=4'
    )
  /** マーケティング */
  const { data: categoryRanking8, error: categoryRanking8Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=8&page=1&limit=4'
    )
  /** ファイナンス */
  const { data: categoryRanking11, error: categoryRanking11Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=11&page=1&limit=4'
    )
  /** 政治・経済 */
  const { data: categoryRanking12, error: categoryRanking12Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=12&page=1&limit=4'
    )
  /** サイエンス */
  const { data: categoryRanking14, error: categoryRanking14Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=14&page=1&limit=4'
    )
  /** リベラルアーツ */
  const { data: categoryRanking15, error: categoryRanking15Error } =
    useSWR<GetSummaryRankingResponse>(
      '/api/summary/ranking?type=weekly&genre=15&page=1&limit=4'
    )
  return (
    <>
      <div className="pt-5 mr-0 sm:mr-[30px]">
        {/** 最新の要約 */}
        <Summary
          summaryResponse={latestSummary}
          title="最新の要約"
          size={8}
          column={4}
          linkToAll="/summary/list"
          showSectionLine={isBizSrpClose}
          isError={!latestSummary.result}
        >
          {topBanner?.special_banners.is_displayed && (
            <SpecialBanner specialBanner={topBanner.special_banners} />
          )}
        </Summary>
        {/* 読書プログラム */}
        {useReadingProgram && readingPrograms.items.length > 0 && (
          <ReadingProgram readingPrograms={readingPrograms} />
        )}
        {/* プレイリスト */}
        <Playlist
          playlists={playlists}
          isTextShowAllButton={true}
          showSectionLine={true}
        />

        {isBizUser ? (
          <>
            {/* 法人CLOSEでSP表示は人気の本を表示しない */}
            {(!isBizSrpClose || !isSp) && (
              <>
                {/** 法人週間ランキング */}
                <Summary
                  summaryResponse={bizWeeklyRanking}
                  title="人気の本(週間)"
                  subtitle={bizGroupName}
                  size={8}
                  column={4}
                  showSectionLine={false}
                  isShowAllButton={false}
                  showRankBadge={true}
                  isError={!bizWeeklyRanking.result}
                />
              </>
            )}
            {/** flierチャンネルバナー */}
            <ChannelBanner />
            {/** いま読んでおきたい話題書 */}
            <Summary
              summaryResponse={masterpiece}
              title="いま読んでおきたい話題書"
              size={masterpieceIndex}
              column={4}
              linkToAll="/summary/masterpiece"
              isTextShowAllButton
              skeltonViewPcColumn={4}
              skeltonViewSpColumn={6}
              showSectionLine={false}
              isError={masterpieceError}
            />
            {/** laboバナー */}
            <Banner
              linkUrl="https://labo.flierinc.com/about"
              pcImage="/top/bnr_labo_pc.png"
              spImage="/top/bnr_labo_sp@2x.png"
              title="flier book labo"
            />
          </>
        ) : (
          <>
            {/** flierチャンネルバナー */}
            <ChannelBanner />
            {/** 週間ランキング */}
            <Summary
              summaryResponse={weeklyRanking}
              title="週間ランキング"
              size={rankingIndex}
              column={4}
              linkToAll="/summary/ranking?tab=weekly"
              linkToAllText="ランキングを見る"
              isTextShowAllButton
              isError={!weeklyRanking.result}
              showRankBadge={true}
            />
            {/** 月間ランキング */}
            <Summary
              summaryResponse={monthlyRanking}
              title="月間ランキング"
              size={rankingIndex}
              column={4}
              linkToAll="/summary/ranking?tab=monthly"
              linkToAllText="ランキングを見る"
              showSectionLine={false}
              isError={!monthlyRanking.result}
            />
            {/** laboバナー */}
            <Banner
              linkUrl="https://labo.flierinc.com/about"
              pcImage="/top/bnr_labo_pc.png"
              spImage="/top/bnr_labo_sp@2x.png"
              title="flier book labo"
            />
            {/** いま読んでおきたい話題書 */}
            <Summary
              summaryResponse={masterpiece}
              title="いま読んでおきたい話題書"
              size={masterpieceIndex}
              column={4}
              linkToAll="/summary/masterpiece"
              isTextShowAllButton
              skeltonViewPcColumn={4}
              skeltonViewSpColumn={6}
              isError={masterpieceError}
            />
          </>
        )}
        {/** あなたにオススメ */}
        <Summary
          summaryResponse={recommendForYou}
          title="あなたにオススメ"
          size={recommendIndex}
          column={4}
          linkToAll="/summary/recommend_you"
          showSectionLine={false}
          skeltonViewPcColumn={8}
          skeltonViewSpColumn={12}
          isError={recommendForYouError}
        />
        {/** 睡眠本のテッパン特集バナー */}
        <Banner
          linkUrl="/pickup/119_teppan"
          pcImage="/top/bnr_teppann_suimin_pc.png"
          spImage="/top/bnr_teppann_suimin_sp@2x.png"
          title="睡眠の鉄板特集"
        />
        {/** flierチャンネル */}
        <Channel
          channelResponse={latestChannel}
          title="flierチャンネル"
          linkToAll="/channel?from=all_view_link"
          isError={latestChannelError}
        />
        {/** イチオシの本 */}
        <Feature
          featureResponse={latestPickUp}
          title="イチオシの本"
          size={2}
          isTextShowAllButton={true}
          isFeatureLg={true}
          linkToAll="/pickup/list/1"
          isError={latestPickUpError}
        />
        {/** インタビュー */}
        <Feature
          featureResponse={latestInterview}
          title="インタビュー"
          size={2}
          isTextShowAllButton={true}
          isFeatureLg={true}
          linkToAll="/interview/list/1"
          isError={latestInterviewError}
        />
        {/** スペシャルコラム */}
        <Feature
          featureResponse={latestColumn}
          title="スペシャルコラム"
          size={2}
          isTextShowAllButton={true}
          isFeatureLg={true}
          linkToAll="/column/list/1"
          isError={latestColumnError}
        />
        {/** スキルアップ・キャリア */}
        <Summary
          summaryResponse={categoryRanking1}
          title="スキルアップ・キャリア"
          size={4}
          column={4}
          linkToAll="/category/1"
          isError={categoryRanking1Error}
        />
        {/** 経営戦略 */}
        <Summary
          summaryResponse={categoryRanking5}
          title="経営戦略"
          size={4}
          column={4}
          linkToAll="/category/5"
          isError={categoryRanking5Error}
        />
        {/** 人事 */}
        <Summary
          summaryResponse={categoryRanking7}
          title="人事"
          size={4}
          column={4}
          linkToAll="/category/7"
          isError={categoryRanking7Error}
        />
        {/** マーケティング */}
        <Summary
          summaryResponse={categoryRanking8}
          title="マーケティング"
          size={4}
          column={4}
          linkToAll="/category/8"
          isError={categoryRanking8Error}
        />
        {/** ファイナンス */}
        <Summary
          summaryResponse={categoryRanking12}
          title="ファイナンス"
          size={4}
          column={4}
          linkToAll="/category/12"
          isError={categoryRanking11Error}
        />
        {/** 政治・経済 */}
        <Summary
          summaryResponse={categoryRanking11}
          title="政治・経済"
          size={4}
          column={4}
          linkToAll="/category/11"
          isError={categoryRanking12Error}
        />
        {/** サイエンス */}
        <Summary
          summaryResponse={categoryRanking14}
          title="サイエンス"
          size={4}
          column={4}
          linkToAll="/category/14"
          isError={categoryRanking14Error}
        />
        {/** リベラルアーツ */}
        <Summary
          summaryResponse={categoryRanking15}
          title="リベラルアーツ"
          size={4}
          column={4}
          linkToAll="/category/15"
          isError={categoryRanking15Error}
        />
        {/** その他のカテゴリー */}
        <Category categoryResponse={categoryList} title="その他のカテゴリー" />
      </div>
    </>
  )
}
