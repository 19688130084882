import Link from 'next/link'
import { GetLatestSummaryResponse } from '@/types/summary'
import { GetChannelMoviesResponse } from '@/types/channel'
import { GetFeatureListResponse } from '@/types/feature'
import { Summary } from './Summary/Summary'
import { Channel } from './Channel/Channel'
import { ChannelBanner } from './Channel/Banner'
import { Feature } from './Feature/Feature'
import { Playlist } from './Playlist'
import { TopBannerResponse } from '@/types/top_banner'
import { SpecialBanner } from './Banner/SpecialBanner'
import { PlaylistsResponse } from '@/types/playlist'

interface Props {
  latestSummary: GetLatestSummaryResponse
  latestChannel?: GetChannelMoviesResponse
  latestPickUp?: GetFeatureListResponse
  latestInterview?: GetFeatureListResponse
  latestColumn?: GetFeatureListResponse
  topBanner?: TopBannerResponse
  playlists: PlaylistsResponse
  usedFreeCoupon: boolean
}

export const Anonymous: React.FC<Props> = ({
  latestSummary,
  latestChannel,
  latestPickUp,
  latestInterview,
  latestColumn,
  topBanner,
  playlists,
  usedFreeCoupon,
}) => {
  return (
    <>
      <div className="p-2 bg-gray-100 sp">
        {!usedFreeCoupon && (
          <Link href="/welcome?via=sidebanner">
            <img
              className="my-2"
              src={`${process.env.IMG_SERVER}/welcome/7days_gold_211022_sp.png`}
              alt="7日間無料でお試し！"
            />
          </Link>
        )}
      </div>
      <div className="pt-5 mr-0 sm:mr-[30px]">
        <ChannelBanner showSectionLine={false} />
        {/** 最新の要約 */}
        <Summary
          summaryResponse={latestSummary}
          title="最新の要約"
          size={10}
          column={4}
          linkToAll="/summary/list"
          showCircularAllButton={true}
          showLargeSummary={true}
          isTextShowAllButton={false}
          isError={!latestSummary.result}
        >
          {topBanner?.special_banners.is_displayed && (
            <SpecialBanner specialBanner={topBanner.special_banners} />
          )}
        </Summary>
        {/* プレイリスト */}
        <Playlist
          playlists={playlists}
          isTextShowAllButton={false}
          showSectionLine={true}
        />

        {/** flierチャンネル */}
        <Channel
          channelResponse={latestChannel}
          title="flierチャンネル"
          linkToAll="/channel?from=all_view_link"
          isTextShowAllButton={false}
        />
        {/** イチオシの本 */}
        <Feature
          featureResponse={latestPickUp}
          title="イチオシの本"
          size={4}
          column={4}
          linkToAll="/pickup/list/1"
          isError={!latestPickUp.result}
        />
        {/** インタビュー */}
        <Feature
          featureResponse={latestInterview}
          title="インタビュー"
          size={4}
          column={4}
          linkToAll="/interview/list/1"
          isError={!latestInterview.result}
        />
        {/** スペシャルコラム */}
        <Feature
          featureResponse={latestColumn}
          title="スペシャルコラム"
          size={4}
          column={4}
          linkToAll="/column/list/1"
          isError={!latestColumn.result}
        />
      </div>
    </>
  )
}
