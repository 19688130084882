import { ArrowRightSmall } from '@/components/icon/ArrowRightSmall'
import Link from 'next/link'

interface Props {
  title: string
  subTitle?: string
  btnText: string
  link: string
}
export const SubTitle: React.FC<Props> = ({
  title,
  subTitle,
  btnText,
  link,
}) => {
  return (
    <div className="flex justify-between items-center w-[278px]">
      <div className="h-[29px] text-[15px] font-bold text-fl-black">
        {title}
        {subTitle && <span className="text-xs font-normal">(直近一ヶ月)</span>}
      </div>
      <Link href={link}>
        <span className="flex justify-center items-center h-5 text-xs not-italic font-bold text-[#1a6dcc]">
          {btnText}
          <ArrowRightSmall color="#1a6dcc" size={13} className="ml-1" />
        </span>
      </Link>
    </div>
  )
}
