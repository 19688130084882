import { Comment } from './comment'
import { BizGroupInfo } from './user'

export interface ErrorResponse {
  result: boolean
  error: string
}

export type BusinessCommentsResponse =
  | {
      biz_comments: Comment[]
      total: number
    }
  | ErrorResponse

// eslint-disable-next-line
export const isErrorResponse = (item: any): item is ErrorResponse => {
  return !item || (item !== undefined && item?.error)
}

interface Author {
  id: number
  name: string
}

interface Publisher {
  id: number
  name: string
}

export interface Summary {
  id: string
  rank: number
  title: string
  subtitle: string
  subpos: number
  authors: Author[]
  publisher: Publisher[]
  release: string
  released: string
  free: number
  ja: boolean
  en: boolean
  new: boolean
  image: string
  chips?: string[]
}

interface BizWeeklyRankingError {
  code: number
  message: string
}

export interface BizWeeklyRankingResponse {
  summaries?: Summary[]
  total?: number
  result?: boolean
  error?: BizWeeklyRankingError
}

interface SchoolInfo {
  theme_total: number
  theme_complete: number
}

export interface BusinessHeaderResponse {
  biz_group_info?: BizGroupInfo
  school_info?: SchoolInfo
  error?: ErrorResponse
}
