interface Props {
  title: string
  value?: string
}

export const Record: React.FC<Props> = ({ title, value }) => {
  return (
    <div className="float-left px-[7px] h-[30px] text-[11px] not-italic font-bold leading-snug text-center text-white border-r border-solid">
      <p className="m-0">{title}</p>
      <p className="m-0">{value}</p>
    </div>
  )
}
