import { Banner } from '../Banner'

interface Props {
  showSectionLine?: boolean
}

export const ChannelBanner: React.FC<Props> = ({ showSectionLine }) => {
  return (
    <Banner
      linkUrl="/channel?from=banner"
      pcImage="/top/bnr_flier_channel_pc_202406.png"
      spImage="/top/bnr_flier_channel_sp_202406.png"
      title="flier チャンネル"
      showSectionLine={showSectionLine}
    />
  )
}
