import { BusinessLabel } from '@/components/label/BusinessLabel'

interface Props {
  name: string
}

export const Title: React.FC<Props> = ({ name }) => {
  return (
    <>
      <BusinessLabel />
      <div className="mt-[15px] text-lg font-bold">{name}</div>
    </>
  )
}
