/* eslint tailwindcss/no-custom-classname: off */
import Link from 'next/link'
import { LatestSummary } from '@/types/summary'
import { SummaryButton } from '@/components/summary/SummaryButton'
import { truncate } from '@/utils/format'

interface Props {
  summary: LatestSummary
}
export const SummaryForAnonymous: React.FC<Props> = ({ summary }) => {
  const { id, title } = summary
  return (
    <>
      <div
        id={id}
        key={`summary-md-${id}`}
        className="block sm:table-cell pb-5 mb-[30px] align-top"
      >
        <div className="table relative w-full sm:w-[352px]">
          {/** 書影 */}
          <div className="table-cell w-[170px] align-top">
            <img
              className="my-0 mx-3 sm:mx-3 w-[calc(100%-24px)] bg-white shadow-[4px_4px_10px_rgba(0,0,0,0.2)]"
              src={`${process.env.IMG_SERVER}/summary/${id}_cover_150.jpg`}
              alt={title}
            />
          </div>
          {/** 詳細 */}
          <div className="table-cell w-[166px] text-justify align-top">
            <div className="mb-2 text-[15px] font-medium leading-snug text-fl-black1">
              {summary.title}
            </div>
            <div className="mb-2 text-[13px] leading-snug text-fl-sky1">
              {summary.authors.map((author) => (
                <span className="pr5" key={author.id}>
                  {author.name}
                </span>
              ))}
            </div>
            {/** 要約ステータスバッジ */}
            <SummaryButton summary={summary} />
            {/** レコメンド文 */}
            <div className="mt-2 text-[13px] text-justify">
              {truncate(summary.recommend, 60)}
            </div>
          </div>

          <Link href={`/summary/${id}`}>
            <div className="absolute top-0 left-0 w-full h-full border-b-0">
              <span className="hidden">{`/summary/${id}`}</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
