/* eslint-disable tailwindcss/no-custom-classname */
import Link from 'next/link'

interface Props {
  linkUrl: string
  pcImage: string
  spImage: string
  title?: string
  showSectionLine?: boolean
  className?: string
  imgClassName?: string
}
export const Banner: React.FC<Props> = ({
  linkUrl,
  pcImage,
  spImage,
  title = 'バナー画像',
  showSectionLine = true,
  className,
  imgClassName,
}) => {
  return (
    <>
      <div className={`pt-5 pb-5 ${className}`}>
        <Link href={linkUrl}>
          <div>
            <picture>
              <source
                media="(max-width: 480px)"
                srcSet={`${process.env.IMG_SERVER}${spImage}`}
              />
              <img
                src={`${process.env.IMG_SERVER}${pcImage}`}
                alt={title}
                className={`m-auto sm:m-0 max-h-[124px] sm:max-h-[100px] ${imgClassName}`}
              />
            </picture>
          </div>
        </Link>
      </div>
      {showSectionLine && <div className="bb-1px-gray"></div>}
    </>
  )
}
