import { Container } from '@/features/global/components/Layout/Container'
import { BizGroupInfo } from '@/types/user'
import { BusinessCommentsResponse } from '@/types/business_group'
import { Container as BizContainer } from './Business/Container'

interface Props {
  bizGroupInfo: BizGroupInfo
  bizCommentsResponse: BusinessCommentsResponse
  isBizSrpClose: boolean
  isReadingProgram: boolean
  children: React.ReactNode
}
export const IndexContainer: React.FC<Props> = ({
  bizGroupInfo,
  bizCommentsResponse,
  isBizSrpClose,
  isReadingProgram,
  children,
}) => {
  return isBizSrpClose ? (
    <BizContainer
      bizGroupInfo={bizGroupInfo}
      bizCommentsResponse={bizCommentsResponse}
      isReadingProgram={isReadingProgram}
    >
      {children}
    </BizContainer>
  ) : (
    <Container sidebar={{ pc: true, sp: false }}>{children}</Container>
  )
}
