import { Comment } from '@/types/comment'
import { isFlierEditor } from '@/utils/user'
import Link from 'next/link'
import { UserProfileIcon } from '@/components/user/UserProfileIcon'

interface Props {
  comment: Comment
}

export const User: React.FC<Props> = ({ comment }) => {
  const { user } = comment

  return (
    <div className="flex items-center">
      <div
        className={`w-[34px] h-[34px] min-w-[34px] min-h-[34px] ${
          isFlierEditor(user.id) && 'pointer-events-none'
        }`}
      >
        <Link href={`/member/${user.srp_id}`} className="!no-underline">
          <UserProfileIcon
            size={34}
            spSize={34}
            src={user.image}
            text={user.name}
          />
        </Link>
      </div>
      <div className="ml-[5px] text-[13px] not-italic font-normal leading-relaxed text-fl-dark">
        <div className="flex items-center">
          <div className="overflow-hidden pr-4 truncate whitespace-no-wrap">
            {isFlierEditor(user.id) ? (
              user.name
            ) : (
              <Link href={`/member/${user.srp_id}`}>
                <div className="w-[150px] text-fl-black truncate">
                  {user.name}
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className="text-xs text-fl-gray">{comment.create_elapsed}</div>
      </div>
    </div>
  )
}
