import { ArrowRightSmall } from '@/components/icon'
interface Props {
  imgSrc: string
  alt: string
  linkName: string
  redirectUri: string
}
export const BannerLink: React.FC<Props> = ({
  imgSrc,
  alt,
  linkName,
  redirectUri,
}) => {
  return (
    <div className="mt-[20px]">
      <a
        className="text-center hover:no-underline hover:opacity-75"
        href={redirectUri}
      >
        <img src={imgSrc} alt={alt} className={'w-[305px]'} />
        <div
          className={
            'flex items-center py-[5px] px-[20px] w-[305px] bg-white rounded-b border border-fl-water border-solid'
          }
        >
          <span className={'flex-1 text-sm font-semibold text-fl-blue'}>
            {linkName}
          </span>
          <ArrowRightSmall color="#1A6DCC" className="" />
        </div>
      </a>
    </div>
  )
}
