import { ChannelMovie } from '@/types/channel'
import dayjs from 'dayjs'
import Link from 'next/link'
import { OverlayNonPublished, OverlayPublished } from './'

interface Props {
  movie: ChannelMovie
}
export const ChannelLg: React.FC<Props> = ({ movie }) => {
  const { id } = movie
  const publishDate = dayjs(movie?.publish_date)
  const isPublished = dayjs().isAfter(publishDate)
  return (
    <Link href={`/channel/series/${movie?.series_id}/movie/${movie?.id}`}>
      <div
        id={`${id}`}
        key={`feature-xs-${id}`}
        style={{
          pointerEvents: isPublished ? 'auto' : 'none',
          textDecoration: 'none',
        }}
      >
        <div className="relative w-full">
          <img
            src={movie?.thumbnails.size_640x360}
            alt={movie?.title}
            loading="lazy"
          />
          {isPublished ? (
            <OverlayPublished />
          ) : (
            <OverlayNonPublished movie={movie} />
          )}
        </div>
        <div className="mt-[10px]">
          <div className="text-sm leading-snug text-left text-fl-black1">
            {movie?.title}
          </div>
          <div className="text-xs text-left text-fl-gray3">
            {publishDate.format('YYYY.MM.DD')} update
          </div>
        </div>
      </div>
    </Link>
  )
}
