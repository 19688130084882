/* eslint-disable tailwindcss/no-custom-classname */
import { isDesktop } from 'react-device-detect'
import {
  ServiceIntro1Icon,
  ServiceIntro2Icon,
  ServiceIntro3Icon,
  ServiceIntro4Icon,
} from '@/components/icon'
import css from 'styled-jsx/css'
import { useEffect, useState } from 'react'
const styles = css`
  .intro {
    background-color: #e5f0fd;
  }
  .intro-title {
    font-size: 30px;
    height: 64px;
    text-align: center;
  }
  .intro-tag {
    display: inline-block;
    border-radius: 2px;
    vertical-align: middle;
    color: #fff;
    font-size: 14px;
    background-color: #fd8637;
    width: 68px;
  }
  .intro-block {
    font-size: 17px;
    text-indent: 1em;
    text-align: justify;
    text-justify: inter-ideograph;
    word-wrap: break-word;
  }
  .intro-section {
    color: #005bab;
    font-size: 18px;
  }
  .intro-content {
    flex-basis: 50%;
  }
`
export const ServiceIntro: React.FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    // PCのみ表示
    setShow(isDesktop)
  }, [])

  return (
    show && (
      <div className="flex w-full intro">
        <div className="flex-1 flex-shrink-0"></div>
        <div className="container p-[6px]">
          <div className="w-full">
            <div className="mt-10 mb-[10px] intro-title">
              <span className="py-0.5 px-2 -mt-2 mr-1 intro-tag">SERVICE</span>
              <span>flierは読書体験を豊かにするディスカバリーサービス</span>
            </div>
            <p className="mb-0 intro-block">
              日本では年間８万冊以上、ビジネス書だけでも年6,000冊もの本が新たに発売されています。そんななか、「書店に並ぶ本の数が多すぎて、何を読めば良いか分からない」「立ち読みや書評だけでは、どんな内容の本なのか十分につかめない」ということはありませんか？
            </p>
            <p className="mb-[30px] intro-block">
              「本の要約サービスflier
              フライヤー」は、多忙なビジネスパーソンが本の内容を効率的につかむことで、ビジネスに役立つ知識・教養を身に付け、スキルアップにつなげることができます。具体的には、新規事業のアイデア、営業訪問時のトークネタ、ビジネストレンドや業界情報の把握、リーダーシップ・コーチングなどです。
            </p>
            <div className="flex mb-[30px] w-full">
              <div className="flex intro-content">
                <ServiceIntro1Icon />
                <div>
                  <div className="mb-[10px] intro-section">
                    いま読むべき書籍をピックアップ！
                  </div>
                  <p className="intro-block">
                    話題の書籍や名著のなかから、経営者や大学教授などの著名人・専門家などが「ビジネスパーソンがいま読むべき本」を選んでいます。
                  </p>
                </div>
              </div>
              <div className="w-6"></div>
              <div className="flex intro-content">
                <ServiceIntro2Icon />
                <div>
                  <div className="mb-[10px] intro-section">
                    高い品質にこだわった要約！
                  </div>
                  <p className="intro-block">
                    経営コンサルタントや各分野の専門家が一冊一冊を精読し、要約を作成しています。社内外に何重ものチェック体制を設け、高い品質にこだわっています。
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mb-[30px] w-full">
              <div className="flex intro-content">
                <ServiceIntro3Icon />
                <div>
                  <div className="mb-[10px] intro-section">
                    いつでもどこでも読める！
                  </div>
                  <p className="block">
                    PC・スマホ・タブレット端末に対応しており、通勤時間や休憩の合間など、いつでもどこでも1冊10分程度で本の内容を味わうことができます。
                  </p>
                </div>
              </div>
              <div className="w-6"></div>
              <div className="flex intro-content">
                <ServiceIntro4Icon />
                <div>
                  <div className="mb-[10px] intro-section">
                    気になった本はECサイトから購入！
                  </div>
                  <p className="block">
                    要約を読んで気になった本は、Amazonなど普段お使いのECサイトから購入することができます。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex-shrink-0"></div>
        <style jsx>{styles}</style>
      </div>
    )
  )
}
