/* eslint tailwindcss/no-custom-classname: off */
import { Carousel as CarouselOne } from '@/types/carousel'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { IconPause } from '@/components/icon/IconPause'
import { IconPlayArrow } from '@/components/icon/IconPlayArrow'
import '@splidejs/react-splide/css'
import css from 'styled-jsx/css'
interface Props {
  carouselList: CarouselOne[]
}

const styles = css`
  .splide__pagination {
    position: relative;
    bottom: 0;
  }
  .splide__pagination :global(.splide__pagination__page) {
    background-color: #000;
    opacity: 0.25;
    margin: 15px;
    cursor: pointer;
  }

  .splide__pagination :global(.splide__pagination__page.is-active) {
    background-color: #000;
    opacity: 0.75;
    margin: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    .splide__pagination :global(.splide__pagination__page) {
      background-color: #000;
      margin: 10px;
    }

    .splide__pagination :global(.splide__pagination__page.is-active) {
      background-color: #1a6dcc;
      margin: 10px;
    }
  }
`

export const Carousel: React.FC<Props> = ({ carouselList }) => {
  if (carouselList.length === 0) {
    return null
  }
  return (
    <Splide
      options={{
        perPage: 3,
        breakpoints: {
          767: {
            perPage: 1,
            gap: 0,
            width: '100%',
            height: 'auto',
          },
        },
        type: 'loop',
        gap: '1rem',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        autoWidth: true,
        arrows: false,
        focus: 'center',
        hasAutoplayProgress: `false`,
        lazyLoad: 'nearby',
        interval: 8000,
      }}
      aria-labelledby="carousel-heading"
      hasTrack={false}
      className="carousels"
    >
      <SplideTrack>
        {carouselList.map((carousel) => {
          const isSummaryLabel =
            carousel.target.key === 'summary' && carousel.target.id > 0
          return (
            <SplideSlide key={carousel.id}>
              <a href={carousel.link}>
                <div className="carousel-item">
                  <div className="rich-carousel">
                    <div>
                      <picture>
                        <source
                          media="(max-width: 767px)"
                          srcSet={`${process.env.IMG_SERVER}/carousel/${carousel.id}_mobile.jpg`}
                        />
                        <img
                          src={`${process.env.IMG_SERVER}/carousel/${carousel.id}.jpg`}
                          alt={carousel.title}
                        />
                      </picture>
                    </div>
                    <div className="carousel-titles arrow-carousel">
                      <div
                        className={`carousel-title ${
                          isSummaryLabel && 'sm:pl-48'
                        }`}
                      >
                        {carousel.title}
                      </div>
                      <div
                        className={`carousel-subtitle ${
                          isSummaryLabel && 'sm:pl-48'
                        }`}
                      >
                        {carousel.subtitle}
                      </div>
                    </div>
                    {carousel.target.key === 'summary' && (
                      <div className="carousel-summary">
                        <img
                          src={`${process.env.IMG_SERVER}/summary/${carousel.target.id}_cover_150.jpg`}
                          alt={carousel.title}
                        />
                      </div>
                    )}
                    {carousel.label ? (
                      <div className="carousel-label">{carousel.label}</div>
                    ) : (
                      isSummaryLabel && (
                        <div className="carousel-label">最新の要約</div>
                      )
                    )}
                  </div>
                </div>
              </a>
            </SplideSlide>
          )
        })}
      </SplideTrack>

      <div className="splide__progress">
        <div className="splide__progress__bar" />
      </div>

      <div className="flex justify-center items-center mt-2">
        <button className="pb-1 ml-1 splide__toggle">
          <IconPlayArrow className="splide__toggle__play" />
          <IconPause className="splide__toggle__pause" />
        </button>
        <div className="splide__pagination"></div>
        <style jsx>{styles}</style>
      </div>
    </Splide>
  )
}
