// @MEMO: npx swagger-typescript-api -p swagger.yaml -o ./ -n myApi.ts にて作成したものからコピー
import { SchoolSummary } from './school'

export type ChannelActors = ChannelActor[]
export type ChannelActor = {
  id: number
  name: string
  image: string
  profile: string
  created: string
  updated: string
}

/**
 * 公開予定のシリーズ告知バナー
 */
export interface ChannelBanner {
  /** バナーID */
  id: number

  /** PC版の画像URL */
  pc: string

  /** SP版の画像URL */
  sp: string

  /** 掲載開始日時 */
  start_date: string

  /** 掲載終了日時 */
  end_date: string

  /** 登録日時 */
  created?: string

  /** 更新日時 */
  updated?: string
}

export type ChannelChapters = { id: number; time: string; title: string }[]

/**
 * 動画情報
 */
export interface ChannelMovie {
  /** @format int32 */
  id: number

  /** 動画URL */
  urls: ChannelMovieUrl

  /** html5プレイヤー */
  embed: string

  /** 動画サムネール */
  thumbnails: ChannelMoviethumbnail

  /** シリーズ概要 */
  series_id: number

  /** 動画タイトル */
  title: string

  /** 動画概要 */
  description: string
  chapters: ChannelChapters

  /** 指定公開日 */
  publish_date?: string
  new: boolean
  program_title?: string
  created: string
  updated: string
}

export type ChannelMovies = ChannelMovie[]

/**
 * 動画サムネール
 */
export interface ChannelMoviethumbnail {
  size_100x75: string
  size_200x150: string
  size_295x166: string
  size_640x360: string
  size_960x540: string
  size_1280x740: string
  size_1920x1080: string
}

/**
 * 動画URL
 */
export interface ChannelMovieUrl {
  hls: string
}

export type ChannelSeries = ChannelOneSeries[]

/**
 * シリーズ情報
 */
export interface ChannelOneSeries {
  /** @format int32 */
  id: number
  title: string

  /** シリーズ概要 */
  description: string

  /** シリーズ概要抜粋 */
  short_description: string

  /** 番組ID */
  program_id?: number

  /** 番組名 */
  program_title?: string

  /** 番組リード文章 */
  program_lead?: string

  /** シリーズサムネール画像 */
  thumbnail: string
  summaries: SchoolSummary[]
  movies: ChannelMovies
  actors: ChannelActors
  guests?: ChannelActors
}

export interface BaseResponse {
  banner: ChannelBanner
  limit: number
  page: number
  total: number
  updated: string
}

export interface ErrorResponse {
  result: boolean
  error: string
}

// eslint-disable-next-line
export const isErrorResponse = (item: any): item is ErrorResponse => {
  return item !== undefined && item?.error
}

export type ChannelSeriesResponse =
  | (BaseResponse & {
      series: ChannelSeries
    })
  | ErrorResponse

export type ChannelOneSeriesResponse =
  | (BaseResponse & {
      series: ChannelOneSeries
    })
  | ErrorResponse

export interface ChannelMoviesResponse extends BaseResponse {
  movies: ChannelMovie[]
}

/** @GET /channel/movies */
export type GetChannelMoviesResponse = ChannelMoviesResponse | ErrorResponse

export interface ChannelErrorResponse {
  result: boolean
  error: string
}

export const isChannelErrorResponse = (
  // eslint-disable-next-line
  item: any
): item is ChannelErrorResponse => {
  return item !== undefined && typeof item?.error === 'string'
}
