import { FeatureType } from '@/types/feature'
import Link from 'next/link'

interface Props {
  type: FeatureType
  address: string
}
export const FeatureLink: React.FC<Props> = ({ type, address }) => {
  return (
    <Link href={`/${type}/${address}`}>
      <div className="absolute top-0 left-0 w-full h-full border-b-0">
        <span className="hidden">リンク</span>
      </div>
    </Link>
  )
}
