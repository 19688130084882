import Link from 'next/link'

interface Props {
  title: string
  link: string
  style: string
  textStyle: string
  children: React.ReactNode
}

export const Button: React.FC<Props> = ({
  title,
  link,
  style,
  textStyle,
  children,
}) => {
  return (
    <Link
      href={link}
      className={`flex justify-center h-[54px] items-center self-stretch bg-white border-none ${style}`}
    >
      {children}
      <span className={`not-italic font-bold text-center ${textStyle}`}>
        {title}
      </span>
    </Link>
  )
}
