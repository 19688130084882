/* eslint tailwindcss/no-custom-classname: off */
import { Record, SubTitle } from '.'
import { BizGroupInfo } from '@/types/user'

interface Props {
  bizGroupInfo: BizGroupInfo
}

export const YourRecord: React.FC<Props> = ({ bizGroupInfo }) => {
  return (
    <>
      <div className="py-4 px-3 mt-3 mb-5 bg-fl-gray2 rounded-[4px] border-[1px] border-fl-gray1">
        <SubTitle
          title="あなたの読書"
          subTitle="(直近一ヶ月)"
          btnText="マイページ"
          link="/mypage"
        />
        <>
          <div className="flex items-center my-[10px] bg-white rounded-[4px] shadow-[0_5px_15px_0px_rgba(0,0,0,0.15)]">
            <Record
              title="読書数"
              recordValue={bizGroupInfo.finish_reading_cnt}
              recordUnit="冊"
              rank={bizGroupInfo.ranks?.finish_reading}
              userTotal={bizGroupInfo.user_total}
              redirectUri="/mypage#books"
            />
            <div className="w-px h-[80px] bg-fl-gray1"></div>
            <Record
              title="学びメモ"
              recordValue={bizGroupInfo.comment_cnt}
              recordUnit="件"
              rank={bizGroupInfo.ranks?.comment}
              userTotal={bizGroupInfo.user_total}
              redirectUri="/mypage#comments"
            />
          </div>
        </>
        <div
          className={'clear-both mt-[10px] text-xs font-normal text-fl-dark-1'}
        >
          ※順位はご自身のデータのみ表示されます
        </div>
      </div>
    </>
  )
}
