import { Releases } from './Release/Releases'
import { CourseId, Srp, UserInfo } from '@/types'
import { NextSeo } from 'next-seo'
import { SsrResponse } from '@/types/index_page'
import {
  Anonymous,
  Carousel,
  Gold,
  IndexContainer,
  ServiceIntro,
  Silver,
} from './'
import { isErrorResponse } from '@/types/error'
import { useBreadcrumb } from '@/hooks/useBreadcrumb'
import { DashBoard } from './Business/Srp/Sp/DashBoard'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useShowLoginModal } from '@/hooks/useShowLoginModal'
interface Props {
  courseId: number
  userInfo: UserInfo
  data?: SsrResponse
}

export const IndexPage: React.FC<Props> = (Props) => {
  const showLoginModal = useShowLoginModal()

  useBreadcrumb([])
  const { courseId, userInfo, data } = Props
  /** 非会員またはフリーユーザーか */
  const isAnonymous = courseId < CourseId.silver
  /** 法人学びメモCLOSEか */
  const isBizSrpClose = +userInfo?.biz_group_use_srp === Srp.CLOSE_SRP
  /** 法人名 */
  const bizGroupName = userInfo?.biz_group_name ?? ''
  /** 法人ユーザーか */
  const isBizUser = userInfo?.biz_group_id > 0
  /** 読書プログラムONでかつ設定済みか */
  const isReadingProgramSet =
    userInfo.use_reading_program && data?.readingPrograms?.items?.length > 0

  // リダイレクトパラメータがある場合はログインモーダルを表示
  const { redirect } = useRouter().query
  useEffect(() => {
    if (redirect && courseId === CourseId.anonymous) {
      showLoginModal()
    }
  }, [redirect])

  /** テンプレート振り分け処理 */
  const renderPage = (courseId: number) => {
    if (courseId === CourseId.gold) {
      /** ゴールドユーザー用 */
      return (
        <Gold
          latestSummary={data?.latestSummary}
          weeklyRanking={data?.weeklyRanking}
          monthlyRanking={data?.monthlyRanking}
          bizWeeklyRanking={data?.bizWeeklyRanking}
          categoryList={data?.categoryList}
          isBizSrpClose={isBizSrpClose}
          bizGroupName={bizGroupName}
          isBizUser={isBizUser}
          topBanner={data?.topBanner}
          playlists={data.playlists}
          useReadingProgram={isReadingProgramSet}
          readingPrograms={data?.readingPrograms}
        />
      )
    } else if (courseId === CourseId.silver) {
      /** シルバーユーザー用 */
      return (
        <Silver
          latestSummary={data?.latestSummary}
          masterpiece={data?.masterpiece}
          freeSummary={data?.freeSummary}
          bizWeeklyRanking={data?.bizWeeklyRanking}
          categoryList={data?.categoryList}
          isBizSrpClose={isBizSrpClose}
          bizGroupName={bizGroupName}
          topBanner={data?.topBanner}
          playlists={data.playlists}
          useReadingProgram={isReadingProgramSet}
          readingPrograms={data?.readingPrograms}
        />
      )
    } else {
      /** 非会員・フリーユーザー用 */
      return (
        <Anonymous
          latestSummary={data?.latestSummary}
          latestChannel={data?.latestChannel}
          latestPickUp={data?.latestPickUp}
          latestInterview={data?.latestInterview}
          latestColumn={data?.latestColumn}
          topBanner={data?.topBanner}
          playlists={data.playlists}
          usedFreeCoupon={userInfo.used_free_coupon}
        />
      )
    }
  }
  return (
    <>
      {!isErrorResponse(data?.carouselList) && data?.carouselList?.result ? (
        <Carousel carouselList={data?.carouselList.carousels} />
      ) : (
        <div>データの取得に失敗しました</div>
      )}
      {isBizSrpClose && (
        <DashBoard
          bizGroupInfo={userInfo?.biz_group_info}
          isReadingProgram={isReadingProgramSet}
        />
      )}
      <IndexContainer
        bizGroupInfo={userInfo?.biz_group_info}
        bizCommentsResponse={data?.bizComments}
        isBizSrpClose={isBizSrpClose}
        isReadingProgram={isReadingProgramSet}
      >
        <NextSeo
          title="本の要約サービス flier(フライヤー)"
          description="flier（フライヤー）はビジネスパーソンや就活生必携の「要約」サービスです。スキマ時間を活用して、厳選した話題のビジネス書・教養書がわずか10分で読める「時短読書」を始めませんか。"
          canonical="https://www.flierinc.com/"
          openGraph={{
            url: 'https://www.flierinc.com',
            images: [
              { url: process.env.IMG_SERVER + '/common/logo_share.jpg' },
            ],
          }}
        />
        {renderPage(courseId)}
      </IndexContainer>
      {/** flier紹介エリア */}
      {isAnonymous && <ServiceIntro />}
      {/** お知らせ */}
      <Releases latestReleases={data?.latestReleases} />
    </>
  )
}
