import { H1 } from '@/components/label'
import { PlaylistL } from './PlaylistsL'
import { PlaylistsResponse } from '@/types/playlist'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { ShowAllButtonText } from '@/components/button/ShowAllButtonText'

interface Props {
  playlists: PlaylistsResponse
  isTextShowAllButton: boolean
  showSectionLine: boolean
}

export const Playlist: React.FC<Props> = ({
  playlists,
  isTextShowAllButton,
  showSectionLine,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1 sm:pb-[20px] mt-[30px] sm:mb-5 text-center">
          <H1 title="プレイリスト" className="pt-2 pb-8" />
        </div>
      </div>
      <PlaylistL playlistShorts={playlists.items} column={4} />
      {isTextShowAllButton ? (
        <div
          className={`pr-[10px] pb-[10px] pl-5 text-right ${
            showSectionLine && ' border-b-[1px] border-gray-200'
          }`}
        >
          <ShowAllButtonText href="/playlists/list/1" />
        </div>
      ) : (
        <div className="pt-5 pb-[30px] text-center border-b-[1px] border-gray-200">
          <ShowAllButton href="/playlists/list/1" />
        </div>
      )}
    </>
  )
}
