/* eslint-disable tailwindcss/no-custom-classname */
import { Release } from '@/types/release'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import Link from 'next/link'
dayjs.locale('ja')

interface Props {
  release: Release
}

export const ReleaseItem: React.FC<Props> = ({ release }) => {
  return (
    <div className="pb-5 mb-5 bb-1px-gray" key={`release-${release.id}`}>
      <div className="w-40">
        <span>{dayjs(release.post).format('YYYY.MM.DD')}</span>
      </div>
      <div className="text-left">
        {release.with_body ? (
          <Link href={`/release/${release.id}`}>
            <div>{release.short}</div>
          </Link>
        ) : (
          <span>{release.short}</span>
        )}
      </div>
    </div>
  )
}
