import { Banner } from '../Banner'
import { SpecialBannerResponse } from '@/types/top_banner'

interface Props {
  specialBanner?: SpecialBannerResponse
}

export const SpecialBanner: React.FC<Props> = ({ specialBanner }) => {
  return (
    <Banner
      linkUrl={specialBanner.link}
      pcImage={specialBanner.image_pc}
      spImage={specialBanner.image_sp}
      className="mx-4 sm:mx-0 mb-10"
      imgClassName="rounded-lg"
      showSectionLine={false}
    />
  )
}
