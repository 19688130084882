/* eslint-disable tailwindcss/no-custom-classname */
import { Comment as IComment } from '@/types/comment'
import { ReadMoreButton } from '@/components/button/ReadMoreButton'

interface Props {
  comment: IComment
  bookTitle: string
  needReadMore: boolean
}

export const Comment: React.FC<Props> = ({
  comment,
  bookTitle,
  needReadMore,
}) => {
  if (!needReadMore)
    return (
      <div>
        <div className="before:absolute before:right-[5px] before:bottom-[-5px] pt-2 pb-0 before:pl-1 text-[13px] font-bold leading-[1.6em] text-fl-dark whitespace-pre-wrap break-words before:bg-fl-gray2 before:content-['...']">
          {bookTitle}
        </div>
        <div className="pt-2 pb-4 text-sm text-fl-dark whitespace-pre-wrap break-words">
          {comment.comment}
        </div>
      </div>
    )
  return (
    <>
      <ReadMoreButton
        showHeight={140}
        align="left"
        fontSize="13px"
        fontWeight="400"
        margin="10px 0 0 0"
      >
        <div className="before:absolute before:right-[3px] before:bottom-0 pt-2 pb-0 before:pl-1 text-[13px] font-bold leading-[1.6em] text-fl-dark whitespace-pre-wrap break-words before:bg-[#fff] before:content-['...']">
          {bookTitle}
        </div>
        <div className="before:absolute before:right-[3px] before:bottom-0 pt-2 pb-0 before:pl-1 text-[13px] font-normal leading-[1.6em] text-fl-dark whitespace-pre-wrap break-words before:bg-[#fff] before:content-['...']">
          {comment.comment}
        </div>
      </ReadMoreButton>
      <div className="mb-2"></div>
    </>
  )
}
