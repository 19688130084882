/* eslint tailwindcss/no-custom-classname: off */
import {
  TabbarRankingIcon,
  ManabimemoIcon,
  ReadingProgramIcon,
} from '@/components/icon'
import { Button } from './'
import { BizGroupInfo } from '@/types/user'

interface Props {
  bizGroupInfo: BizGroupInfo
  isReadingProgram: boolean
}

export const DashBoard: React.FC<Props> = ({
  bizGroupInfo,
  isReadingProgram,
}) => {
  return (
    <div className="p-4 bg-fl-blue sp">
      <div className="flex items-center">
        <div className="mr-auto h-5 text-[12px] font-bold leading-5 text-white">
          あなたの読書
          <span className="text-xs font-normal">(直近一ヶ月)</span>
        </div>
        <div className="mx-2 h-4 border-r opacity-20"></div>
        <div className="flex h-5 text-[12px] font-bold leading-5 text-white">
          <p>読書数</p>
          <p className="ml-1">{bizGroupInfo.ranks?.finish_reading}</p>
        </div>
        <div className="mx-2 h-4 border-r opacity-20"></div>
        <div className="flex h-5 text-[12px] font-bold leading-5 text-white">
          <p>学びメモ</p>
          <p className="ml-1">{bizGroupInfo.ranks?.comment}</p>
        </div>
      </div>
      <div className="flex gap-1 mt-3">
        <Button
          title="みんなのメモ"
          link="/business_group/comments?via=top_biz_ranking"
          style={`pt-2 pb-1 w-1/2 rounded-[5px] relative bg-white/20 ${
            isReadingProgram ? 'flex-col' : 'flex-row'
          }`}
          textStyle="text-xs text-white"
        >
          <ManabimemoIcon
            className={`w-5 h-5 text-white ${isReadingProgram ? '' : 'mr-1'}`}
          />
        </Button>
        {isReadingProgram && (
          <Button
            title="読書プログラム"
            link="/reading-programs/list"
            style="flex-col pt-2 pb-1 w-1/2 rounded-[5px] relative bg-white/20"
            textStyle="text-xs text-white"
          >
            <ReadingProgramIcon
              className={`w-5 h-5 text-white ${isReadingProgram ? '' : 'mr-1'}`}
            />
          </Button>
        )}
        <Button
          title="人気の本"
          link="/business_group/summary/ranking"
          style={`pt-2 pb-1 w-1/2 rounded-[5px] relative bg-white/20 ${
            isReadingProgram ? 'flex-col' : 'flex-row'
          }`}
          textStyle="text-xs text-white"
        >
          <TabbarRankingIcon
            className={`w-5 h-5 text-white ${isReadingProgram ? '' : 'mr-1'}`}
          />
        </Button>
      </div>
    </div>
  )
}
