/* eslint tailwindcss/no-custom-classname: off */
import { useRouter } from 'next/router'

interface Props {
  title: string
  recordValue: number
  recordUnit: string
  rank?: string | undefined
  userTotal?: number | undefined
  redirectUri?: string | undefined
}

export const Record: React.FC<Props> = ({
  title,
  recordValue,
  recordUnit,
  rank,
  userTotal,
  redirectUri,
}) => {
  const router = useRouter()
  const redirectTo = () => router.push(redirectUri)
  return (
    <div
      className={
        'flex flex-col items-center py-[20px] w-[139px] hover:bg-fl-gray2 cursor-pointer'
      }
      onClick={redirectTo}
    >
      <div className={'text-sm font-bold'}>{title}</div>
      <div>
        <span
          className={
            'flex items-end text-[28px] font-bold leading-8 text-fl-blue'
          }
        >
          {recordValue}
          <span className={'mx-1 h-[22px] text-sm text-fl-black'}>
            {recordUnit}
          </span>
        </span>
      </div>
      {rank && userTotal && (
        <>
          <div className={'text-xs font-normal text-fl-dark-1'}>
            {rank} / {userTotal}人中
          </div>
        </>
      )}
    </div>
  )
}
