/* eslint tailwindcss/no-custom-classname: off */
import { SummaryMd } from './SummaryMd'
import { chunk } from '@/utils/format'
import { SummaryUnderLinePC } from '@/components/summary/SummaryUnderLinePC'
import { SummaryUnderLineSP } from '@/components/summary/SummaryUnderLineSP'
import {
  LatestSummary,
  RankingSummary,
  TopRecommendSummary,
} from '@/types/summary'
import { Summary as BizSummary } from '@/types/business_group'

interface Props {
  summaries:
    | LatestSummary[]
    | TopRecommendSummary[]
    | RankingSummary[]
    | BizSummary[]
  column: number
  showUnderLine?: boolean
  showRankBadge?: boolean
}

export const SummariesMd: React.FC<Props> = ({
  summaries,
  column,
  showUnderLine = false,
  showRankBadge = false,
}) => {
  const iterateSummaries = chunk<
    LatestSummary | RankingSummary | TopRecommendSummary | BizSummary
  >(summaries, column)
  const isDisplayUnderLine = (idx: number) => {
    return summaries.length / column !== idx + 1
  }
  return (
    <>
      <div className="mb-10">
        {iterateSummaries.map((iterateSummary, index) => (
          <div key={`summary-table-${iterateSummary[0]['id']}`}>
            <div className="table sm:p-0 px-[10px] mb-0">
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4">
                  <div className="relative w-[170px]">
                    <SummaryMd
                      summary={iterateSummary[0]}
                      showRankBadge={showRankBadge}
                    />
                  </div>
                  <div className="relative w-[170px]">
                    {iterateSummary[1] && (
                      <SummaryMd
                        summary={iterateSummary[1]}
                        showRankBadge={showRankBadge}
                      />
                    )}
                  </div>
                </div>
                {/* 要約ごとの下線区切りMOBILE用 */}
                <div className="mt-5 mb-[30px] sp">
                  <div className="flex gap-x-4">
                    <div
                      className={`relative w-[170px] ${
                        iterateSummary[0] ? 'bb-1px-gray' : ''
                      }`}
                    />
                    <div
                      className={`relative w-[170px] ${
                        iterateSummary[1] ? 'bb-1px-gray' : ''
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4">
                  <div className="relative w-[170px]">
                    {iterateSummary[2] && (
                      <SummaryMd
                        summary={iterateSummary[2]}
                        showRankBadge={showRankBadge}
                      />
                    )}
                  </div>
                  <div className="relative w-[170px]">
                    {iterateSummary[3] && (
                      <SummaryMd
                        summary={iterateSummary[3]}
                        showRankBadge={showRankBadge}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showUnderLine ? (
              <>
                <SummaryUnderLinePC
                  isDisplay={isDisplayUnderLine(index)}
                  show_underline_1={0 in iterateSummary}
                  show_underline_2={1 in iterateSummary}
                  show_underline_3={2 in iterateSummary}
                  show_underline_4={3 in iterateSummary}
                />
                <SummaryUnderLineSP
                  isDisplay={isDisplayUnderLine(index)}
                  show_underline_3={2 in iterateSummary}
                  show_underline_4={3 in iterateSummary}
                />
              </>
            ) : (
              <>
                <div className="mb-[30px] pc"></div>
                <SummaryUnderLineSP
                  isDisplay={isDisplayUnderLine(index)}
                  show_underline_3={2 in iterateSummary}
                  show_underline_4={3 in iterateSummary}
                />
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
