import { Play } from '@/components/icon/Play'

export const OverlayPublished: React.FC = () => {
  return (
    <div
      className="absolute top-1/2 left-1/2"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <div className="pc">
        <Play width={27} height={39} />
      </div>
      <div className="sp">
        <Play width={20} height={30} />
      </div>
    </div>
  )
}
