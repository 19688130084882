/* istanbul ignore file */
/**
 * @var string 初回訪問フラグをローカルストレージに保存する際のキー名
 */
const VISIT_FLAG_KEY = 'flier_visited_manabimemo'
const ACTIVATE_CLUSTER_KEY = 'flier_activate_cluster_first_time'

/**
 * 続きを読むボタンが必要か判定する
 * @param str 文字列
 * @return  boolean true: 必要、false: 不要
 */
export function needReadMore(str: string): boolean {
  const newlineCount = str.split('\n').length
  return newlineCount > 2 || str.length > 130
}

/**
 * 初回訪問かを判定
 *
 * @return true: 初回訪問, false: 初回訪問ではない
 */
export function isFirstVisit(): boolean {
  const existVisitFlag = localStorage.getItem(VISIT_FLAG_KEY)
  if (existVisitFlag) return false
  return true
}

/**
 * 初回訪問フラグをセット
 */
export function setVisit(): void {
  localStorage.setItem(VISIT_FLAG_KEY, '1')
}

export function isFirstEnabledAfterCluster(): boolean {
  const existVisitFlag = localStorage.getItem(ACTIVATE_CLUSTER_KEY)
  if (existVisitFlag) return false
  return true
}

export function setFirstEnabledAfterClusterKey(): void {
  localStorage.setItem(ACTIVATE_CLUSTER_KEY, '1')
}
