/* eslint tailwindcss/no-custom-classname: off */
import { chunk } from '@/utils/format'
import { LatestSummary } from '@/types'
import { SummaryForAnonymous } from './SummaryForAnonymous'

interface Props {
  summaries: LatestSummary[]
  column: number
}

export const SummariesLg: React.FC<Props> = ({ summaries, column }) => {
  const iterateSummaries = chunk(summaries, column)
  return (
    <>
      <div className="mb-10">
        {iterateSummaries.map((iterateSummary) => (
          <div key={`summary-table-${iterateSummary[0]['id']}`}>
            <div className="table px-[10px] sm:px-0 mb-0">
              <SummaryForAnonymous summary={iterateSummary[0]} />
              <div className="w-3"></div>
              {iterateSummary[1] && (
                <SummaryForAnonymous summary={iterateSummary[1]} />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
