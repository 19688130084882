import Link from 'next/link'
import { Comment as IComment } from '@/types/comment'
import { needReadMore } from '@/features/business_group/components/Comments/util'
import { LargeButton, User, GrayLine, Comment } from './'
import { ActionButtons } from '@/components/button/ActionButtons/ActionButtons'
import { ArrowRightSmall, ManabimemoIcon } from '@/components/icon'
import { useRouter } from 'next/router'
import { BizGroupInfo } from '@/types/user'
import { BizGroupClusterNameLabel } from '@/components/label/BizGroupClusterNameLabel'

interface Props {
  bizGroupInfo: BizGroupInfo
  bizComments: IComment[]
}
export const SrpForEveryone: React.FC<Props> = ({
  bizGroupInfo,
  bizComments,
}) => {
  const router = useRouter()
  const onClick = () => {
    router.push('/business_group/comments?via=top_biz_ranking')
  }
  if (!bizComments) return null
  return (
    <>
      <div className="mt-2 rounded-[4px] border-[1px] border-fl-gray1">
        <button className="w-full" onClick={onClick}>
          <div className="flex justify-between items-center p-3 font-bold hover:bg-fl-gray2">
            <div className="flex gap-[5px] items-center text-[14px]">
              <ManabimemoIcon className="w-6 h-6 text-[#1a6dcc]" />
              みんなの学びメモ
            </div>
            <span className="flex justify-center items-center text-xs not-italic font-bold text-[#1a6dcc]">
              一覧を見る
              <ArrowRightSmall color="#1a6dcc" size={13} className="ml-1" />
            </span>
          </div>
        </button>
        {bizComments.map((comment) => (
          <div key={comment.id}>
            <GrayLine />
            <div className="py-4 px-3">
              <div className="flex justify-between">
                <div className="w-[200px]">
                  <User comment={comment} />
                  <Comment
                    comment={comment}
                    bookTitle={comment.summary.title}
                    needReadMore={needReadMore(comment.comment)}
                  />
                  <BizGroupClusterNameLabel
                    bizGroupClusters={bizGroupInfo?.biz_group_clusters ?? []}
                    clusterId={comment.biz_group_cluster_id}
                    useBizGroupCluster={bizGroupInfo?.use_biz_group_cluster}
                    className="max-w-full w-fit"
                  />
                  <div className="mt-4">
                    <ActionButtons comment={comment} />
                  </div>
                </div>
                <div className="w-16">
                  <Link href={`/summary/${comment.summary.id}`}>
                    <div>
                      <img
                        className="shadow-flier"
                        src={
                          process.env.IMG_SERVER +
                          `/summary/${comment.summary.id}_cover_300.jpg`
                        }
                        alt={comment.summary.title}
                        width="80"
                        height="120"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/** 学びメモをもっと見る */}
        <GrayLine />
        <div className="p-3">
          <LargeButton
            text="学びメモをもっと見る"
            link="/business_group/comments?via=sidebar"
          />
        </div>
      </div>
    </>
  )
}
