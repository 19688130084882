import dayjs from 'dayjs'
import { ChannelMovie } from '@/types/channel'
interface Props {
  movie: ChannelMovie
}
export const OverlayNonPublished: React.FC<Props> = ({ movie }) => {
  return (
    <div className="flex absolute top-0 justify-center items-center w-full h-full text-center bg-overlay">
      <div className="text-xs sm:text-lg font-bold text-white">
        {dayjs(movie?.publish_date).format('M月DD日(ddd) HH:mm')}
        <br />
        公開予定
      </div>
    </div>
  )
}
