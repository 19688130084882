import { ReadingProgram } from '@/types/reading_program'
import Link from 'next/link'
import { truncate } from '@/utils/format'
import { getMinPriorityTag } from '@/features/index/components/Playlist/utils'
import { isNew } from '@/utils/time'
import { NewLabel } from '@/components/label'

interface Props {
  readingProgram: ReadingProgram
}

export const ReadingProgramCard: React.FC<Props> = ({ readingProgram }) => {
  const topTag = getMinPriorityTag(readingProgram.tags)
  return (
    <>
      <div
        id={`${readingProgram.id}`}
        key={`reading-program-xs-${readingProgram.id}`}
      >
        <div className="w-full">
          <img
            className="mx-auto w-36 bg-white rounded"
            src={readingProgram.image}
            alt={readingProgram.name}
            loading="lazy"
          />
        </div>
        <div className="ml-2 sm:ml-0">
          <div className="mt-[10px]">
            <div className="text-sm font-normal leading-snug text-left text-fl-black1">
              <span className="pc">{truncate(readingProgram.name, 21)}</span>
              <span className="sp">{truncate(readingProgram.name, 23)}</span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex flex-wrap px-1 pb-4 mt-1 h-3 text-[10px] font-normal text-center text-fl-dark bg-fl-gray2 rounded-[66.5px]">
              {topTag.name}
            </div>
          </div>
          <div className="flex items-center mt-1">
            <div className="mr-2 h-3 text-[10px] font-normal sm:font-bold text-fl-dark">
              全{readingProgram.summary_length}冊
            </div>
            <div>
              {isNew(readingProgram.released) && (
                <NewLabel className="py-0 w-[33px] h-[17px]" />
              )}
            </div>
          </div>
        </div>

        <Link
          href={`/reading-programs/${readingProgram.id}`}
          className="absolute top-0 left-0 w-full h-full border-b-0"
        />
      </div>
    </>
  )
}
