import { ArrowRightSmall } from '@/components/icon/ArrowRightSmall'
import Link from 'next/link'

interface Props {
  text: string
  link: string
}
export const LargeButton: React.FC<Props> = ({ text, link }) => {
  return (
    <div>
      <Link href={link}>
        <button className="py-[5px] px-5 w-full bg-white hover:bg-[#e7e7e7] rounded border-[1px] border-[#ccdeee] border-solid cursor-pointer">
          <span className="text-sm font-semibold text-[#1a6dcc]">
            {text}
            <ArrowRightSmall
              color="#1a6dcc"
              size={20}
              className="float-right mt-[5px]"
            />
          </span>
        </button>
      </Link>
    </div>
  )
}
