import { PlaylistShort } from '@/types/playlist'
import Link from 'next/link'
import { truncate } from '@/utils/format'
import { getMinPriorityTag } from './utils'
import { isNew } from '@/utils/time'
import { NewLabel } from '@/components/label'

interface Props {
  playlistShort: PlaylistShort
}

export const PlaylistXs: React.FC<Props> = ({ playlistShort }) => {
  const topTag = getMinPriorityTag(playlistShort.tags)
  return (
    <>
      <div id={`${playlistShort.id}`} key={`playlist-xs-${playlistShort.id}`}>
        <div className="w-full">
          <img
            className="mx-auto w-36 bg-white rounded"
            src={playlistShort.image}
            alt={playlistShort.name}
            loading="lazy"
          />
        </div>
        <div className="ml-2 sm:ml-0">
          <div className="mt-[10px]">
            <div className="text-sm font-normal leading-snug text-left text-fl-black1">
              <span className="pc">{truncate(playlistShort.name, 21)}</span>
              <span className="sp">{truncate(playlistShort.name, 23)}</span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex flex-wrap px-1 pb-4 mt-1 h-3 text-[10px] font-normal text-center text-fl-dark bg-fl-gray2 rounded-[66.5px]">
              {topTag.name}
            </div>
          </div>
          <div className="flex items-center mt-1">
            <div className="mr-2 h-3 text-[10px] font-normal sm:font-bold text-fl-dark">
              全{playlistShort.summary_length}冊
            </div>
            <div>
              {isNew(playlistShort.released) && (
                <NewLabel className="py-0 w-[33px] h-[17px]" />
              )}
            </div>
          </div>
        </div>

        <Link
          href={`/playlists/${playlistShort.id}`}
          className="absolute top-0 left-0 w-full h-full border-b-0"
        />
      </div>
    </>
  )
}
