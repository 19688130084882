/* eslint tailwindcss/no-custom-classname: off */
import { BizGroupInfo } from '@/types/user'
import { BusinessCommentsResponse } from '@/types/business_group'
import { Sidebar } from './Sidebar'

interface Props {
  bizGroupInfo: BizGroupInfo
  bizCommentsResponse: BusinessCommentsResponse
  isReadingProgram?: boolean
  children?: React.ReactNode
}

export const Container: React.FC<Props> = ({
  bizGroupInfo,
  bizCommentsResponse,
  isReadingProgram,
  children,
}) => {
  return (
    <div className="flexbox">
      <div className="flex1"></div>
      <div className="container">
        {children}
        <Sidebar
          bizGroupInfo={bizGroupInfo}
          bizCommentsResponse={bizCommentsResponse}
          isReadingProgram={isReadingProgram}
        />
      </div>
      <div className="flex1"></div>
    </div>
  )
}
