import { H1 } from '@/components/label'
import { GetCategoryListResponse } from '@/types/category'
import { isErrorResponse } from '@/types/error'
import { CategoryChild } from './CategoryChild'

interface Props {
  /** カテゴリーレスポンス */
  categoryResponse: GetCategoryListResponse
  /** エリアのタイトル */
  title: string
}
export const Category: React.FC<Props> = ({ categoryResponse, title }) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1 pb-[20px] mt-[30px] mb-5 text-center">
          <H1 title={title} className="pt-2" />
        </div>
      </div>
      {!isErrorResponse(categoryResponse) && categoryResponse?.result ? (
        <div className="block">
          {categoryResponse.categories.map((category) => {
            return (
              <CategoryChild
                key={`category-${category.id}`}
                link={`${category.id}`}
                title={category.name}
              />
            )
          })}
        </div>
      ) : (
        <div>データの取得に失敗しました</div>
      )}
    </>
  )
}
