interface Props {
  length?: number
}
export const SkeltonViewMd: React.FC<Props> = ({ length = 4 }) => {
  return (
    <div className="animate-pulse">
      <div className="pb-20 w-full">
        <div className="flex flex-wrap">
          {Array(length)
            .fill(0)
            .map((_, i) => (
              <div key={i} className="p-2 w-1/2 sm:w-1/4 max-w-sm">
                <div className="flex-1 py-1 space-y-3">
                  <div className="h-40 sm:h-48 bg-gray-300 rounded"></div>
                  <div className="w-3/4 sm:w-4/5 h-4 sm:h-6 bg-gray-300 rounded"></div>
                  <div className="w-1/3 h-3 sm:h-4 bg-gray-300 rounded"></div>
                  <div className="w-1/2 h-3 bg-gray-300 rounded"></div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
