/* eslint tailwindcss/no-custom-classname: off */
import {
  isRankingSummaryResponse,
  LatestSummary,
  RankingSummary,
  TopRecommendSummary,
} from '@/types/summary'
import { Summary as BizSummary } from '@/types/business_group'
import { SummaryLink } from '@/components/summary/SummaryLink'
import { SummaryImage } from '@/components/summary/SummaryImage'
import { SummaryDetail } from '@/components/summary/SummaryDetail'
import { RankBadge } from '@/components/summary'
interface Props {
  summary: LatestSummary | RankingSummary | TopRecommendSummary | BizSummary
  showRankBadge?: boolean
}
export const SummaryMd: React.FC<Props> = ({ summary, showRankBadge }) => {
  const { id, title } = summary
  return (
    <>
      <div id={id} key={`summary-md-${id}`}>
        {showRankBadge && isRankingSummaryResponse(summary) && (
          /** ランキングの場合はランクバッジを表示する */
          <RankBadge rank={summary.rank} />
        )}
        <SummaryImage id={id} title={title} size="150" />
        <SummaryDetail summary={summary} />
        <SummaryLink id={id} />
      </div>
    </>
  )
}
