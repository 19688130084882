/* eslint tailwindcss/no-custom-classname: off */
import { Feature } from '@/types/feature'
import dayjs from 'dayjs'

interface Props {
  feature: Feature
}

export const FeatureDetail: React.FC<Props> = ({ feature }) => {
  return (
    <div className="mt-[10px]">
      <div className="text-sm leading-snug text-left text-fl-black1">
        {feature.corner && (
          <>
            <span>【{feature.corner}】</span>
          </>
        )}
        <span>{feature.title}</span>
      </div>
      <div className="text-xs text-left text-fl-gray3">
        {dayjs(feature.released).format('YYYY.MM.DD')} update
      </div>
    </div>
  )
}
