import Link from 'next/link'
interface Props {
  href: string
  text?: string
}

export const ShowAllButtonText: React.FC<Props> = ({ href, text }) => {
  return (
    <Link href={href}>
      <div className="text-[15px] text-fl-black underline">
        <span>{text ? text : 'すべてを見る'}</span>
      </div>
    </Link>
  )
}
