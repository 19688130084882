import Link from 'next/link'

interface Props {
  link: string
  title: string
}
export const CategoryChild: React.FC<Props> = ({ link, title }) => {
  return (
    <Link href={`/category/${link}`}>
      <button
        type="button"
        className="relative px-5 m-1 h-11 text-white align-middle bg-[#316ab0] rounded-[40px] cursor-pointer"
      >
        {title}
      </button>
    </Link>
  )
}
