/* eslint tailwindcss/no-custom-classname: off */
import { Feature } from '@/types/feature'
import dayjs from 'dayjs'
import Link from 'next/link'

interface Props {
  feature: Feature
}

export const FeatureLg: React.FC<Props> = ({ feature }) => {
  const { id, type, title, corner, released, address } = feature
  return (
    <Link href={`/${type}/${address}`}>
      <div
        id={`${id}`}
        key={`feature-lg-${id}`}
        style={{
          textDecoration: 'none',
        }}
      >
        <div className="relative w-full">
          <img
            src={`${process.env.IMG_SERVER}/feature/${id}_eyecatch.jpg`}
            alt={title}
            loading="lazy"
          />
        </div>
        <div className="mt-[10px]">
          <div className="text-sm leading-snug text-left text-fl-black1">
            {corner && <span>【{corner}】</span>}
            <span>{title}</span>
          </div>
          <div className="text-xs text-left text-fl-gray3">
            {dayjs(released).format('YYYY.MM.DD')} update
          </div>
        </div>
      </div>
    </Link>
  )
}
