/* eslint-disable tailwindcss/no-custom-classname */
import { H1 } from '@/components/label'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { ShowAllButtonText } from '@/components/button/ShowAllButtonText'
import { isErrorResponse } from '@/types/error'
import { GetFeatureListResponse } from '@/types/feature'
import { FeaturesL, FeaturesLg } from './'
import { SkeltonViewLg } from '../SkeltonViewLg'

interface Props {
  /** 特集レスポンス */
  featureResponse: GetFeatureListResponse
  /** エリアのタイトル */
  title: string
  /** 表示する要素の数 */
  size: number
  /** 1セットの個数 */
  column?: number
  /** 「すべてを見る」ボタンのリンクURL */
  linkToAll: string
  /** エリア下部の区切り線の有無 */
  showSectionLine?: boolean
  /** 「すべてを見る」をテキストリンクにするか */
  isTextShowAllButton?: boolean
  /** 大きいサイズで表示するか */
  isFeatureLg?: boolean
  /** エラーはあるか？ */
  isError?: boolean
}

export const Feature: React.FC<Props> = ({
  featureResponse,
  title,
  size,
  column,
  linkToAll,
  showSectionLine = true,
  isTextShowAllButton,
  isFeatureLg,
  isError,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1 pb-[20px] mt-[30px] mb-5 text-center">
          <H1 title={title} className="pt-2 pb-8" />
        </div>
      </div>
      {!isErrorResponse(featureResponse) && featureResponse?.result ? (
        <>
          {isFeatureLg ? (
            <FeaturesLg features={featureResponse.features?.slice(0, 2)} />
          ) : (
            <FeaturesL
              features={featureResponse.features?.slice(0, size)}
              column={column}
            />
          )}
          {isTextShowAllButton ? (
            <div
              className={`pr-[10px] pb-[10px] pl-5 text-right ${
                showSectionLine && 'bb-1px-gray'
              }`}
            >
              <ShowAllButtonText href={linkToAll} />
            </div>
          ) : (
            <div className="pt-5 pb-[30px] text-center bb-1px-gray">
              <ShowAllButton href={linkToAll} />
            </div>
          )}
        </>
      ) : (
        <>
          {isError && <div>データの取得に失敗しました</div>}
          <div>{<SkeltonViewLg />}</div>
        </>
      )}
    </>
  )
}
