/* eslint tailwindcss/no-custom-classname: off */
import { FeatureLink } from '@/components/feature/FeatureLink'
import { FeatureImage } from '@/components/feature/FeatureImage'
import { FeatureDetail } from '@/components/feature/FeatureDetail'
import { Feature } from '@/types/feature'

interface Props {
  feature: Feature
}

export const FeatureXs: React.FC<Props> = ({ feature }) => {
  const { id, title } = feature
  return (
    <div id={`${id}`} key={`feature-xs-${id}`}>
      <FeatureImage id={`${id}`} title={title} />
      <FeatureDetail feature={feature} />
      <FeatureLink type={feature.type} address={feature.address} />
    </div>
  )
}
