import auth from '@/utils/auth'
import { GetServerSideProps } from 'next'
import { IndexPage } from '@/features/index/components/IndexPage'
import { ApiClient, getHeaders } from '@/libs/api'
import { UserInfo } from '@/types'
import { CourseId } from '@/types'
export default IndexPage

/**
 * Promise.allSettledから結果を取得
 */
const getResult = (res: PromiseSettledResult<unknown>) => {
  return res.status === 'fulfilled' ? res.value : { result: false }
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { req, res } = ctx
  const isGuest = (await auth.isLogin(req, res)) ? false : true
  const headers = getHeaders(req, isGuest)
  const client = new ApiClient(req, res)
  /** ユーザー情報取得 */
  const userInfo = await client.get<UserInfo>('/user/info', null, { headers })
  const courseId = userInfo?.course?.id ?? CourseId.anonymous

  if (courseId == CourseId.gold) {
    const urls = [
      '/carousel/web',
      '/summary/list?page=1&limit=8',
      '/summary/ranking?type=weekly&genre=0&page=1&limit=6',
      '/summary/ranking?type=monthly&genre=0&page=1&limit=6',
      '/business_group/summary/ranking?type=weekly&page=1&limit=8',
      '/business_group/comments?target=all&sort=created&order=desc&page=1&limit=4',
      '/category',
      '/release?page=1&limit=5',
      '/top/banners',
      '/playlists?sort=top&limit=8',
      '/reading-programs?sort=top&limit=8',
    ]
    const response = await Promise.allSettled(
      urls.map((url) => client.get(url))
    )
    const [
      carouselList,
      latestSummary,
      weeklyRanking,
      monthlyRanking,
      bizWeeklyRanking,
      bizComments,
      categoryList,
      latestReleases,
      topBanner,
      playlists,
      readingPrograms,
    ] = response.map(getResult)

    return {
      props: {
        courseId: courseId,
        userInfo: userInfo,
        data: {
          carouselList,
          latestSummary,
          weeklyRanking,
          monthlyRanking,
          bizWeeklyRanking,
          bizComments,
          categoryList,
          latestReleases,
          topBanner,
          playlists,
          readingPrograms,
        },
      },
    }
  } else if (courseId == CourseId.silver) {
    const urls = [
      '/carousel/web',
      '/summary/masterpieces?page=1&limit=4',
      '/summary/list?page=1&limit=8',
      '/summary/free?page=1&limit=4',
      '/business_group/summary/ranking?type=weekly&page=1&limit=8',
      '/business_group/comments?target=all&sort=created&order=desc&page=1&limit=4',
      '/category',
      '/release?page=1&limit=5',
      '/top/banners',
      '/playlists?sort=top&limit=8',
      '/reading-programs?sort=top&limit=8',
    ]
    const response = await Promise.allSettled(
      urls.map((url) => client.get(url))
    )
    const [
      carouselList,
      masterpiece,
      latestSummary,
      freeSummary,
      bizWeeklyRanking,
      bizComments,
      categoryList,
      latestReleases,
      topBanner,
      playlists,
      readingPrograms,
    ] = response.map(getResult)
    return {
      props: {
        courseId,
        userInfo,
        data: {
          carouselList,
          masterpiece,
          latestSummary,
          freeSummary,
          bizWeeklyRanking,
          bizComments,
          categoryList,
          latestReleases,
          topBanner,
          playlists,
          readingPrograms,
        },
      },
    }
  } else {
    const urls = [
      '/carousel/web',
      '/summary/list?page=1&limit=10',
      '/channel/movies?page=1&limit=2',
      '/feature/pickup?page=1&limit=4',
      '/feature/interview?page=1&limit=4',
      '/feature/column?page=1&limit=4',
      '/release?page=1&limit=5',
      '/top/banners',
      '/playlists?sort=top&limit=8',
    ]
    const guestHeaders = getHeaders(req, true)
    const response = await Promise.allSettled(
      urls.map((url) => client.get(url, null, { headers: guestHeaders }))
    )
    const [
      carouselList,
      latestSummary,
      latestChannel,
      latestPickUp,
      latestInterview,
      latestColumn,
      latestReleases,
      topBanner,
      playlists,
    ] = response.map(getResult)

    return {
      props: {
        courseId,
        userInfo,
        data: {
          carouselList,
          latestSummary,
          latestChannel,
          latestPickUp,
          latestInterview,
          latestColumn,
          latestReleases,
          topBanner,
          playlists,
        },
      },
    }
  }
}
