/* eslint-disable tailwindcss/no-custom-classname */
import { H1 } from '@/components/label'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import {
  GetChannelMoviesResponse,
  isChannelErrorResponse,
} from '@/types/channel'
import { ChannelsLg } from './ChannelsLg'
import { SkeltonViewLg } from '../SkeltonViewLg'
import { ShowAllButtonText } from '@/components/button/ShowAllButtonText'

interface Props {
  /** チャンネルレスポンス */
  channelResponse: GetChannelMoviesResponse
  /** エリアのタイトル */
  title: string
  /** 「すべてを見る」ボタンのリンクURL */
  linkToAll: string
  /** 「すべてを見る」ボタンのテキスト */
  linkToAllText?: string
  /** エリア下部の区切り線の有無 */
  showSectionLine?: boolean
  /** 「すべてを見る」をテキストリンクにするか */
  isTextShowAllButton?: boolean
  /** エラーはあるか？ */
  isError?: boolean
}

export const Channel: React.FC<Props> = ({
  channelResponse,
  title,
  linkToAll,
  linkToAllText = 'すべてを見る',
  showSectionLine = true,
  isTextShowAllButton = true,
  isError,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1 pb-[20px] mt-[30px] mb-5 text-center">
          <H1 title={title} className="pt-2 pb-8" />
        </div>
      </div>
      {!isChannelErrorResponse(channelResponse) && channelResponse?.movies ? (
        <>
          <ChannelsLg movies={channelResponse.movies?.slice(0, 2)} />
          {isTextShowAllButton ? (
            <div
              className={`pr-[10px] pb-[10px] pl-5 text-right ${
                showSectionLine && 'bb-1px-gray'
              }`}
            >
              <ShowAllButtonText href={linkToAll} text={linkToAllText} />
            </div>
          ) : (
            <div className="pt-5 pb-[30px] text-center bb-1px-gray">
              <ShowAllButton href={linkToAll} />
            </div>
          )}
        </>
      ) : (
        <>
          {isError && <div>データの取得に失敗しました</div>}
          <div>{<SkeltonViewLg />}</div>
        </>
      )}
    </>
  )
}
