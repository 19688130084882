/* eslint tailwindcss/no-custom-classname: off */
import {
  BusinessCommentsResponse,
  isErrorResponse,
} from '@/types/business_group'
import {
  BannerLink,
  YourRecord,
  Title,
  ReadingProgramLink,
} from '@/components/sidebar/Business'
import { SrpForEveryone } from './'
import { BizGroupInfo } from '@/types/user'

interface Props {
  bizGroupInfo: BizGroupInfo
  bizCommentsResponse: BusinessCommentsResponse
  isReadingProgram?: boolean
}
export const Sidebar: React.FC<Props> = ({
  bizGroupInfo,
  bizCommentsResponse,
  isReadingProgram,
}) => {
  return (
    <div className="px-[20px] pt-[30px] w-[366px] vtop pc">
      <Title name={bizGroupInfo.name} />
      {/** あなたの読書 */}
      <YourRecord bizGroupInfo={bizGroupInfo} />
      {/** 読書プログラム */}
      {isReadingProgram && <ReadingProgramLink />}
      {/** みんなの学びメモ */}
      {!isErrorResponse(bizCommentsResponse) &&
        bizCommentsResponse.biz_comments && (
          <>
            <SrpForEveryone
              bizGroupInfo={bizGroupInfo}
              bizComments={bizCommentsResponse.biz_comments}
            />
          </>
        )}
      {/** 学びメモの使い方 */}
      <BannerLink
        linkName={'学びメモの使い方'}
        alt={'学びメモの使い方'}
        imgSrc={`${process.env.IMG_SERVER}/sidebar/eyecatch_userguide_memo.png`}
        redirectUri="/column/how-to-use-manabimemo"
      />
    </div>
  )
}
