/* eslint tailwindcss/no-custom-classname: off */
import { FeatureLg } from './FeatureLg'
import { Feature } from '@/types/feature'

interface Props {
  features: Feature[]
}

export const FeaturesLg: React.FC<Props> = ({ features }) => {
  if (features.length === 0) {
    return null
  }
  return (
    <>
      <div className="mb-10">
        <div key={`feature-table-${features[0]['id']}`}>
          <div className="table sm:p-0 px-[10px] mb-0">
            <div className="block sm:table-cell align-top">
              <div className="block sm:flex w-full">
                <div className="relative pb-5 my-4 mb-[30px] w-full sm:w-[352px] bg-white">
                  <FeatureLg feature={features[0]} />
                </div>
                <div className="spc10"></div>
                <div className="relative pb-5 my-4 mb-[30px] w-full sm:w-[352px] bg-white">
                  <FeatureLg feature={features[1]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
