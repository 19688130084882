import { ReadingProgramCard } from './ReadingProgramCard'
import { chunk } from '@/utils/format'
import { ReadingProgram } from '@/types/reading_program'

interface Props {
  readingProgram: ReadingProgram[]
  column: number
}

export const ReadingProgramLine: React.FC<Props> = ({
  readingProgram,
  column,
}) => {
  const iterateReadingPrograms = chunk(readingProgram, column)
  return (
    <>
      <div className="mb-10">
        {iterateReadingPrograms.map((iterateReadingProgram) => (
          <div key={`reading-program-table-${iterateReadingProgram[0]['id']}`}>
            <div className="table sm:p-0 px-[10px] mb-0">
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4 w-full">
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    <ReadingProgramCard
                      readingProgram={iterateReadingProgram[0]}
                    />
                  </div>
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    {iterateReadingProgram[1] && (
                      <ReadingProgramCard
                        readingProgram={iterateReadingProgram[1]}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="block sm:table-cell align-top">
                <div className="flex gap-x-4 w-full">
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    {iterateReadingProgram[2] && (
                      <ReadingProgramCard
                        readingProgram={iterateReadingProgram[2]}
                      />
                    )}
                  </div>
                  <div className="relative my-4 mx-0 w-full sm:w-40 bg-white hover:bg-fl-gray2">
                    {iterateReadingProgram[3] && (
                      <ReadingProgramCard
                        readingProgram={iterateReadingProgram[3]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
