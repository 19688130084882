/* eslint-disable tailwindcss/no-custom-classname */
import { H1 } from '@/components/label'
import { ShowAllButton } from '@/components/button/ShowAllButton'
import { ShowAllButtonText } from '@/components/button/ShowAllButtonText'
import { isErrorResponse } from '@/types/error'
import {
  GetLatestSummaryResponse,
  GetSummaryRankingResponse,
  GetTopRecommendSummaryResponse,
  LatestSummary,
} from '@/types/summary'
import { BizWeeklyRankingResponse } from '@/types/business_group'
import { SummariesLg, SummariesMd } from './'
import { SkeltonViewMd } from '../SkeltonViewMd'
import { useRouter } from 'next/router'
import { CircularButton } from '@/components/button/CircularButton'

interface Props {
  /** 要約レスポンス */
  summaryResponse:
    | GetLatestSummaryResponse
    | GetSummaryRankingResponse
    | GetTopRecommendSummaryResponse
    | BizWeeklyRankingResponse
  /** エリアのタイトル */
  title: string
  /** エリアのサブタイトル */
  subtitle?: string
  /** 表示する要素の数 */
  size: number
  /** 1セットの個数 */
  column: number
  /** 「すべてを見る」ボタンのリンクURL */
  linkToAll?: string
  /** 「すべてを見る」ボタンのテキスト */
  linkToAllText?: string
  /** PC向けスケルトンビューの要素の数 */
  skeltonViewPcColumn?: number
  /** SP向けスケルトンビューの要素の数 */
  skeltonViewSpColumn?: number
  /** 「すべてを見る」ボタンを楕円形にするか */
  showCircularAllButton?: boolean
  /** 大きいサイズも表示するか */
  showLargeSummary?: boolean
  /** 書影に下線を付与するか */
  showUnderLine?: boolean
  /** エリア下部の区切り線の有無 */
  showSectionLine?: boolean
  /** 書影にランクバッジを付与するか */
  showRankBadge?: boolean
  /** 「すべてを見る」をテキストリンクにするか */
  isTextShowAllButton?: boolean
  /** 「すべてを見る」を表示するか */
  isShowAllButton?: boolean
  /** エラーはあるか？ */
  isError?: boolean
  /** バナー挿入用 */
  children?: React.ReactNode
}

export const Summary: React.FC<Props> = ({
  summaryResponse,
  title,
  subtitle,
  size,
  column,
  linkToAll,
  linkToAllText = 'すべてを見る',
  skeltonViewPcColumn = 4,
  skeltonViewSpColumn = 4,
  showCircularAllButton = false, // 主に非会員・フリー向け
  showLargeSummary = false, // 主に非会員・フリー向け
  showUnderLine = false,
  showSectionLine = true,
  showRankBadge = false,
  isTextShowAllButton = true,
  isShowAllButton = true,
  isError = false,
  children,
}) => {
  const router = useRouter()
  return (
    <>
      <div className={children ? `flex` : `flex mb-10`}>
        <div className="flex-1 mt-[30px] text-center">
          {subtitle && (
            <div className="pb-[10px] text-xl not-italic font-bold text-fl-black">
              {subtitle}
            </div>
          )}
          <H1 title={title} className="pt-2 pb-8" />
        </div>
        {showCircularAllButton && (
          <div className="block mt-[30px] text-center pc">
            <CircularButton
              text="すべてを見る"
              onClick={() => router.push(linkToAll)}
            />
          </div>
        )}
      </div>
      {children}
      {!isErrorResponse(summaryResponse) &&
      summaryResponse?.summaries?.length > 0 ? (
        <>
          {showLargeSummary && (
            <SummariesLg
              summaries={
                summaryResponse.summaries?.slice(0, 2) as LatestSummary[]
              }
              column={2}
            />
          )}
          <SummariesMd
            summaries={summaryResponse.summaries?.slice(
              showLargeSummary ? 2 : 0,
              size
            )}
            column={column}
            showUnderLine={showUnderLine}
            showRankBadge={showRankBadge}
          />
          {isShowAllButton &&
            (isTextShowAllButton ? (
              <div
                className={`pr-[10px] pb-[10px] pl-5 text-right ${
                  showSectionLine && 'bb-1px-gray'
                }`}
              >
                <ShowAllButtonText href={linkToAll} text={linkToAllText} />
              </div>
            ) : (
              <div className="pt-5 pb-[30px] text-center bb-1px-gray">
                <ShowAllButton href={linkToAll} />
              </div>
            ))}
        </>
      ) : (
        <>
          {isError && <div>データの取得に失敗しました</div>}
          <div className="pc">
            {<SkeltonViewMd length={skeltonViewPcColumn} />}
          </div>
          <div className="sp">
            {<SkeltonViewMd length={skeltonViewSpColumn} />}
          </div>
        </>
      )}
    </>
  )
}
