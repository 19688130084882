import { H1 } from '@/components/label'
import { H1Business } from '@/components/label/H1Business'
import { ReadingProgramLine } from './ReadingProgramLine'
import { ReadingProgramsResponse } from '@/types/reading_program'
import { ShowAllButtonText } from '@/components/button/ShowAllButtonText'

interface Props {
  readingPrograms: ReadingProgramsResponse
}

export const ReadingProgram: React.FC<Props> = ({ readingPrograms }) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1 sm:pb-[20px] mt-[30px] sm:mb-5 text-center">
          <H1 title="読書プログラム" className="pt-2 pb-8 pc" />
          <H1Business title="読書プログラム" className="pt-2 pb-8 sp" />
        </div>
      </div>
      <ReadingProgramLine readingProgram={readingPrograms.items} column={4} />
      <div className="pr-[10px] pb-[10px] pl-5 text-right border-b-[1px] border-gray-200">
        <ShowAllButtonText href="/reading-programs/list/1" />
      </div>
    </>
  )
}
